
.help-info {
    height: 100%;
    padding: 34px 20px;
    ::v-deep .el-form-item__label {
        text-align: right;
    }
    .class-item {
        border-bottom: 1px solid #DCDCDC;
        padding-bottom: 22px;
    }
    .content-item {
        display: flex;
        flex-direction: column;
        height: calc(100% - 187px);
        ::v-deep .el-form-item__content {
            height: calc(100% - 50px);
            #help_content {
                height: 100%;
                ::v-deep .w-e-text-container {
                    max-height: calc(100% - 52px) !important;
                    min-height: calc(100% - 52px) !important;
                }
            }
        }
        ::v-deep .el-form-item__label {
            margin-bottom: 10px;
            width: 80px;
        }
    }
    .save-item {
        text-align: center;
        .el-button {
            background-color: #1E33E4;
            border-color: #1E33E4;
        }
    }
}
